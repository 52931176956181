<template>
  <!--所有页面的底部5大导航tab-->
  <van-tabbar id="tabbar" v-model="active" active-color="#000" inactive-color="#000" @change="onChange">
    <van-tabbar-item v-for="(tabar, index) in tablist " :key="tabar.text">
      <span>{{ tabar.text }}</span>
      <template #icon="props">
        <img :src="path == tabar.url ? tabar.active : tabar.inactive">
      </template>
    </van-tabbar-item>

  </van-tabbar>
</template>

<script>
export default {
  name: 'TabBar',
  props: {
    tab: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      // active: 0,
      home: require('/@themes/tabbar/tab_home_active.png'),
      tablist: [
        {
          text: '首页',
          url: '/',
          active: require('/@themes/theme/primary/tab_home_active.png'),
          inactive: require('/@themes/tabbar/tab_home.png')
        },
        {
          text: '中山小主人',
          url: '/industryStudy',
          active: require('/@themes/theme/red/tab_service_active.png'),
          inactive: require('/@themes/tabbar/tab_service.png')
        },
        {
          text: '订单',
          url: '/order',
          active: require('/@themes/theme/red/tab_order_active.png'),
          inactive: require('/@themes/tabbar/tab_order.png')
        },
        {
          text: '购物车',
          url: '/cart',
          active: require('/@themes/theme/red/tab_car_active.png'),
          inactive: require('/@themes/tabbar/tab_car.png')
        },

        {
          text: '我的',
          url: '/user',
          active: require('/@themes/theme/red/tab_wo_active.png'),
          inactive: require('/@themes/tabbar/tab_wo.png')
        }
      ],
      homeActive: {
        red: require('/@themes/theme/red/tab_home_active.png'),
        blue: require('/@themes/theme/blue/tab_home_active.png'),
        primary: require('/@themes/theme/primary/tab_home_active.png'),
        orange: require('/@themes/theme/orange/tab_home_active.png'),
        tide: require('/@themes/theme/tide/tab_home_active.png'),
        mid_autumn: require('/@themes/theme/mid_autumn/tab_home_active.png'),
        NewYear: require('/@themes/theme/NewYear/tab_home_active.png'),
        nezha: require('/@themes/theme/NewYear/tab_home_active.png'),
        'dragon-boat': require('/@themes/theme/dragon-boat/tab_home_active.png')
      }
    }
  },
  computed: {
    appTheme() {
      return this.$store.state.appTheme
    },
    active: {
      get() {
        // 获取当前路由的路径,主要用于选中哪一个tabbar
        const path = this.$route.path
        // console.log(path)
        switch (path) {
          case '/':
            return 0
          case '/service':
            return 1
          case '/order':
            return 2
          case '/cart':
            return 3
          case '/user':
            return 4
          default:
            return -1
        }
      },
      set() {
      }
    },
    path() {
      return this.$route.path
    }
  },
  watch: {
    appTheme(to, from) {
      this.tablist[0].active = this.homeActive[to]
    },
    path(to, from) {
      switch (to) {
        case '/':
          this.active = 0
          break
        case '/service':
          this.active = 1
          break
        case '/order':
          this.active = 2
          break
        case '/cart':
          this.active = 3
          break
        case '/user':
          this.active = 4
          break
        default:
          this.active = -1
          break
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tablist[0].active = this.homeActive[this.$store.state.appTheme]
    })
  },
  methods: {
    // tabbar改变切换页面
    onChange(index) {
      const url = this.tablist[index].url
      if(url.startsWith('/')) {
        this.$router.push(url)
        return
      }

      window.location.href = url
    }
  }
}
</script>
